import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import copy from 'copy-to-clipboard';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { Message } from 'shared/hooks/useDb';

import { queryClient } from 'app/App';
import logoSrc from 'app/assets/images/logo.svg';
import { messagesKeys } from 'shared/api/chat/queryKeys';
import { useDeleteMessageMutation } from 'shared/api/chat/useDeleteMessageMutation';
import { useGetFileByCidQuery } from 'shared/api/ipfs/useGetFileByCidQuery';
import { Icon } from 'shared/ui/Icon';
import { IconButton } from 'shared/ui/IconButton';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  disabled: boolean;
  message: Message;
  onRegenerateMessage: (messageId: string) => void;
  prevMessage?: Message;
};

export const ChatMessage = ({ disabled, message, onRegenerateMessage, prevMessage }: Props) => {
  const [isImageExpanded, setIsImageExpanded] = useState(false);
  const { data: fileData, isLoading: isLoadingFile } = useGetFileByCidQuery(message.content!, {
    enabled: !!message.isIPFS && !!message.content,
  });

  const toastId = useRef<number | string>();

  const { isPending: deleteMessageLoading, mutateAsync: deleteMessage } = useDeleteMessageMutation();

  const handleDeleteMessage = async () => {
    const data = queryClient.getQueryData<Message[]>(messagesKeys.chatId(message.chatId));
    if (data) {
      queryClient.setQueryData(
        messagesKeys.chatId(message.chatId),
        data.filter((el) => el.id !== message.id && el.id !== prevMessage?.id),
      );
    }

    await deleteMessage({ messageId: message.id });
    if (prevMessage) {
      await deleteMessage({ messageId: prevMessage.id });
    }
    queryClient.invalidateQueries({ queryKey: ['allChats', message.chatId] });
    queryClient.invalidateQueries({ queryKey: messagesKeys.chatId(message.chatId) });
  };

  return (
    <div className="mb-4 flex gap-2">
      {message.role === 'ai' && (
        <div className="flex size-8 min-w-8 items-center justify-center rounded-full bg-primary-50 p-1">
          <img className="-mt-px size-full " src={logoSrc} />
          {/* <Icon className="-mt-px size-full text-white" name="logo" /> */}
        </div>
      )}
      <div className={twMerge('relative mr-auto flex flex-col', message.role === 'user' && 'ml-auto mr-0')}>
        <div
          className={twMerge(
            'relative mr-auto max-w-96 rounded-2xl bg-corduroy-200 px-4 pb-4 pt-2 font-light',
            // 'mb-4 mr-auto h-auto max-w-96 rounded-2xl bg-corduroy-200 px-4 pb-4 pt-2 font-light',
            message.role === 'user' && 'mb-0 ml-auto mr-0 bg-primary-100',
            message.isIPFS && 'relative w-full overflow-hidden p-0',
          )}
        >
          {message.isIPFS ? (
            isLoadingFile ? (
              <div className="size-8 min-h-12 min-w-10 py-2 pl-3 pr-4">
                <Spinner className="size-4" />
              </div>
            ) : (
              fileData?.type === 'image' && (
                <div className="relative flex flex-col overflow-hidden">
                  <Icon
                    className="absolute right-2 top-2 size-5 cursor-pointer rounded-lg bg-corduroy-200 p-1 text-corduroy-600 transition-colors hover:text-primary-800"
                    name="arrowsOut"
                    onClick={() => setIsImageExpanded(true)}
                  />
                  <img
                    alt="image"
                    className="mr-auto object-contain"
                    // className="mr-auto max-h-[-webkit-fill-available] max-w-[-webkit-fill-available] object-contain"
                    src={fileData?.data}
                  />
                </div>
              )
            )
          ) : (
            message.content
          )}

          {message.timestamp && (
            <span
              className={twMerge(
                'absolute bottom-0 right-2 z-0 text-[10px] text-corduroy-500',
                message.isIPFS &&
                  'right-0 ml-auto flex w-full items-end justify-end bg-corduroy-200 px-2 text-right',
              )}
            >
              {DateTime.fromMillis(message.timestamp).toFormat('HH:mm')}
            </span>
          )}
        </div>
        {message.role === 'ai' && (
          <div className="mt-1 flex items-center gap-2 pl-2">
            <IconButton
              name="copy"
              onClick={() => {
                toastId.current = toast.success('Copied', {
                  toastId: toastId.current || undefined,
                  updateId: toastId.current || undefined,
                });
                copy(message.content);
              }}
            />
            <IconButton
              disabled={deleteMessageLoading}
              name="trash"
              onClick={() => {
                handleDeleteMessage();
              }}
            />
            <IconButton disabled={disabled} name="refresh" onClick={() => onRegenerateMessage(message.id)} />
          </div>
        )}
        {message.role === 'ai' && (
          <div className="absolute -top-4 left-0 text-xs font-light text-corduroy-500">
            {message.executionTime}s
          </div>
        )}
      </div>

      {fileData?.data && (
        <ImageModal imgSrc={fileData?.data} isOpen={isImageExpanded} onOpenChange={setIsImageExpanded} />
      )}
    </div>
  );
};

type ModalProps = {
  imgSrc: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};
const ImageModal = ({ imgSrc, isOpen, onOpenChange }: ModalProps) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content
        className="relative flex h-auto !max-w-fit flex-col items-center justify-center"
        contentClassName="p-0 flex flex-col"
      >
        <Modal.CloseButton className="rounded-lg bg-corduroy-200" />

        <Modal.Title className="-z-50 hidden">Image</Modal.Title>

        {/* <div
          className="size-full min-h-full min-w-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${imgSrc})`,
          }}
        /> */}

        {/* <div className="size-full bg-contain bg-no-repeat" style={{ backgroundImage: `url(${imgSrc})` }} /> */}

        <img
          alt="image"
          className="size-full object-contain"
          // className="size-full object-cover"
          // className="mr-auto max-h-[-webkit-fill-available] max-w-[-webkit-fill-available] object-contain"
          src={imgSrc}
        />
      </Modal.Content>
    </Modal>
  );
};
