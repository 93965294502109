import { type DBSchema, openDB } from 'idb';
import { v4 as uuidv4 } from 'uuid';

import type { LocalUser } from 'app/stores/user';
import type { ModelType } from 'features/ChatInput/ChatInput';

export type Chat = {
  chatId: string;
  chatName?: string;
  folderPath?: string;
  modelType: ModelType;
  sessionId?: string;
  timestamp: number;
  userId?: string;
  wallet?: string;
};

export type Message = {
  chatId: string;
  content: string;
  executionTime?: number;
  id: string;
  isIPFS?: boolean;
  role: 'ai' | 'user';
  timestamp: number;
};

//DirectSecp256k1HdWallet
export type UserWallet = {
  modelType: ModelType;
  secret: { data: string };
  timestamp: number;
};

interface NesaDB extends DBSchema {
  chats: {
    indexes: { modelType: ModelType };
    key: string;
    value: Chat;
  };
  messages: {
    indexes: { chatId: string };
    key: string;
    value: Message;
  };
  user: { key: string; value: LocalUser };
  wallet: { indexes: { modelType: ModelType }; key: string; value: UserWallet };
}

const defaultDbName = 'nesa-db';
const defaultDbVersion = 4;

const dbPromise = openDB<NesaDB>(defaultDbName, defaultDbVersion, {
  blocked(version) {
    console.log('blocked', version);
  },
  terminated() {
    console.log('terminated');
  },
  upgrade(db) {
    console.log('update', db.objectStoreNames);
    // db.createObjectStore('keyval');

    // if the data object store doesn't exist, create it
    if (!db.objectStoreNames.contains('chats')) {
      console.log('Creating chats store');
      const store = db.createObjectStore('chats', { keyPath: 'chatId' });
      store.createIndex('modelType', 'modelType', { unique: false });
    }
    if (!db.objectStoreNames.contains('messages')) {
      console.log('Creating messages store');
      const messagesStore = db.createObjectStore('messages', { autoIncrement: true, keyPath: 'id' });
      messagesStore.createIndex('chatId', 'chatId', { unique: false });
    }
    if (!db.objectStoreNames.contains('wallet')) {
      console.log('Creating wallet store');
      const walletStore = db.createObjectStore('wallet', { autoIncrement: true, keyPath: 'id' });
      walletStore.createIndex('modelType', 'modelType', { unique: true });
    }
    if (!db.objectStoreNames.contains('user')) {
      console.log('Creating user store');
      db.createObjectStore('user', { keyPath: '_id' });
    } else {
      db.deleteObjectStore('user');
      db.createObjectStore('user', { keyPath: '_id' });
    }
  },
});

export const getAllChats = async () => {
  const db = await dbPromise;
  // console.log('db', db);

  const data = await db.getAll('chats');

  console.log('all chats', data);

  return data;
};

export const getChatById = async (chatId: string) => {
  const db = await dbPromise;

  const data = await db.get('chats', chatId);

  return data;
};

export const createChat = async (chat: Omit<Chat, 'chatId' | 'timestamp'>) => {
  const db = await dbPromise;
  const generatedChatId = uuidv4();

  const chatId = await db.add('chats', {
    ...chat,
    chatId: generatedChatId,
    timestamp: Date.now(),
  });

  return chatId;
};

export const updateChat = async (chat: Chat) => {
  const db = await dbPromise;

  const chatId = await db.put('chats', chat);

  return chatId;
};

export const deleteChat = async (chatId: string) => {
  const db = await dbPromise;
  return db.transaction('chats', 'readwrite').objectStore('chats').delete(chatId);
};

export const deleteMessage = async (messageId: string) => {
  const db = await dbPromise;
  return db.transaction('messages', 'readwrite').objectStore('messages').delete(messageId);
};

export const createMessage = async (message: Omit<Message, 'id' | 'timestamp'>) => {
  const db = await dbPromise;

  const messageId = await db.add('messages', { ...message, id: uuidv4(), timestamp: Date.now() });
  const createdMessage = await db.get('messages', messageId);

  return createdMessage;
};

export const updateMessage = async (message: Omit<Message, 'timestamp'>) => {
  const db = await dbPromise;

  const updatedMessage = { ...message, timestamp: Date.now() };
  const messageId = await db.put('messages', updatedMessage);

  console.log('messageId', messageId);

  return updatedMessage;
};

export const getMessagesByChatId = async (chatId: string) => {
  const db = await dbPromise;
  // console.log('db', db);

  const messages = await db.getAllFromIndex('messages', 'chatId', chatId);

  console.log('messages', messages);

  return messages;
};

export const createWallet = async (wallet: Omit<UserWallet, 'timestamp'>) => {
  const db = await dbPromise;

  const walletId = await db.add('wallet', {
    ...wallet,
    timestamp: Date.now(),
  });
  const createdWallet = await db.get('wallet', walletId);

  return createdWallet;
};

export const getWallet = async (type: ModelType) => {
  const db = await dbPromise;

  const wallet = await db.getAll('wallet');

  return wallet.find((item) => item.modelType === type);
};

// export const createUser = async () => {
//   const db = await dbPromise;
//   console.log('createUser');

//   const allUsers = await db.getAll('user');

//   console.log('allUsers', allUsers);

//   if (allUsers.length > 0) return allUsers[0];

//   const userId = await db.add('user', { created_at: Date.now(), id: uuidv4() });
//   const createdUer = await db.get('user', userId);

//   console.log('createdUer', createdUer);

//   return createdUer;
// };

export const saveUser = async (user: LocalUser) => {
  const db = await dbPromise;
  console.log('saveUser');

  const userDb = await db.get('user', user._id);
  let userId = user._id;

  if (userDb) {
    userId = await db.put('user', user);
    const createdUser = await db.get('user', userId);

    return createdUser;
  }

  userId = await db.add('user', user);
  const createdUser = await db.get('user', userId);

  console.log('saveUser', createdUser);

  return createdUser;
};

export const getUser = async (): Promise<LocalUser | null> => {
  const db = await dbPromise;

  const users = await db.getAll('user');

  return users[0] || null;
};
