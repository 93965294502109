import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { useAccount } from 'graz';
import { twJoin } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import type { Chat } from 'shared/hooks/useDb';

import { queryClient } from 'app/App';
import textLogoSrc from 'app/assets/images/logo-text.svg';
import { useUser } from 'app/stores/user';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { useBotInfoQuery } from 'shared/api/telegram';
import { useAuthByCodeMutation, useCheckCodeMutation } from 'shared/api/user/auth';
import { createChat, getChatById } from 'shared/hooks/useDb';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Sidebar } from 'shared/ui/Sidebar';
import { Tooltip } from 'shared/ui/Tooltip';

import { Points } from './ui/Points';

const links = [
  { icon: 'friends', label: 'Friends', to: '/friends' },
  { icon: 'boltSolid', label: 'Energy', to: '/energy' },
] as const;

export const Header = () => {
  const navigate = useNavigate();

  const lg = useMinWidthMediaQuery('lg');

  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const { setUser, user } = useUser();
  const [isSignUpLoading, setIsLoadingSignUp] = useState(false);

  const { data: account } = useAccount();
  // const { disconnectAsync } = useDisconnect();

  const [isProviderOpen, setIsProviderOpen] = useState(false);

  const { mutateAsync: createNewChat } = useMutation({ mutationFn: createChat });

  const { data: info, isPending: isPendingIfo } = useBotInfoQuery();

  const mutateAuthCode = useAuthByCodeMutation();
  const mutateCheckCode = useCheckCodeMutation();

  const handleCreateNewChat = async () => {
    const chatId = await createNewChat({
      modelType: 'text',
      wallet: undefined,
    });

    const chat = await getChatById(chatId);

    const allChats = queryClient.getQueryData<Chat[]>(['allChats']) || [];

    queryClient.setQueryData(['allChats'], [...allChats, chat]);

    navigate(`/chat/${chatId}`);
  };

  const signUp = () => {
    if (!info) return;
    setIsLoadingSignUp(true);

    const authCode = uuidv4();

    window.open(`https://t.me/${info.username}/shhfun?startapp=authCode=${authCode}`, '_blank');

    async function checkCode() {
      try {
        const { status } = await mutateCheckCode.mutateAsync({ code: authCode });

        if (status) {
          const user = await mutateAuthCode.mutateAsync({ code: authCode });
          setUser(user);
          setIsLoadingSignUp(false);
        } else {
          setTimeout(checkCode, 1000);
        }
      } catch (err) {
        console.error(err);
        setIsLoadingSignUp(false);
      }
    }
    checkCode();
  };

  return (
    <div className="absolute top-0 z-20 flex h-16 w-full items-center justify-between pl-8 pr-4 pt-2">
      <div className="flex items-center ">
        <Tooltip alignOffset={10} content="+ New chat" side="bottom">
          <h1
            className="relative -translate-y-2 cursor-pointer select-none text-lg uppercase"
            onClick={handleCreateNewChat}
          >
            <img className="max-w-28" src={textLogoSrc} />
          </h1>
        </Tooltip>

        {lg && user && (
          <div className="ml-10 flex gap-10">
            {links.map((el) => (
              <NavLink
                className="cursor-pointer text-base tracking-normal text-corduroy-500 hover:text-primary-800"
                key={el.to}
                to={el.to}
              >
                {el.label}
              </NavLink>
            ))}
          </div>
        )}
      </div>

      {lg && (
        <div className="flex items-center gap-4">
          {!user && (
            <Button
              color="primary"
              isLoading={isPendingIfo || isSignUpLoading}
              onClick={signUp}
              variant="filled-light"
            >
              Sign Up
            </Button>
          )}
          {user && (
            <div className="flex items-center gap-1">
              <div className="flex items-center gap-1">
                <Points points={user.points} />
                Points
              </div>

              <Tooltip content="If you want to redeem points please connect a wallet" side="bottom">
                <Icon
                  className="cursor-pointer text-corduroy-500 transition-colors hover:text-primary-800"
                  name="infoCircle"
                />
              </Tooltip>
            </div>
          )}

          <div>{!account && <Button onClick={() => setIsProviderOpen(true)}>Connect Wallet</Button>}</div>
        </div>
      )}

      {!lg && (
        <button className="relative size-6 text-corduroy-900" onClick={() => setIsOpenSidebar(true)}>
          <div className={twJoin('absolute h-0.5 w-6 rounded-sm bg-corduroy-500', 'top-1')} />
          <div
            className={twJoin('absolute h-0.5 w-6 rounded-sm bg-corduroy-500', 'top-1/2 -translate-y-1/2')}
          />
          <div className={twJoin('absolute h-0.5 w-6 rounded-sm bg-corduroy-500', 'bottom-1')} />
        </button>
      )}

      <WalletProviderModal isOpen={isProviderOpen} onOpenChange={setIsProviderOpen} />

      {!lg && (
        <Sidebar
          className="flex h-full flex-col"
          isOpen={isOpenSidebar}
          onClose={() => setIsOpenSidebar(false)}
        >
          <div className="mb-8 flex items-center justify-between py-2">
            <NavLink to={'/'}>
              <img className="max-w-28" src={textLogoSrc} />
            </NavLink>
            <Icon className="size-6" name="close" onClick={() => setIsOpenSidebar(false)} />
          </div>
          <div className="flex-1">
            {user && (
              <div className="mb-12 flex flex-col gap-2">
                {links.map((el) => (
                  <NavLink
                    className="flex cursor-pointer items-center gap-3 p-2 text-base tracking-normal text-corduroy-500 hover:text-primary-800"
                    key={el.to}
                    onClick={() => setIsOpenSidebar(false)}
                    to={el.to}
                  >
                    <Icon className="size-5" name={el.icon} />
                    {el.label}
                  </NavLink>
                ))}
              </div>
            )}
          </div>
          {!user && (
            <Button
              className="mb-4 flex w-full"
              color="primary"
              isLoading={isPendingIfo || isSignUpLoading}
              onClick={() => signUp()}
              variant="filled-light"
            >
              Sign Up
            </Button>
          )}
          {!account && (
            <Button className="mb-4 flex w-full" onClick={() => setIsProviderOpen(true)}>
              Connect Wallet
            </Button>
          )}
        </Sidebar>
      )}
    </div>
  );
};
