import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { InviteFriendsButton } from 'features/invite-friends-button';
import { useUserFriendsQuery } from 'shared/api/user/useGetFriends';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { AnimatedRoute } from 'shared/ui/AnimatedRoute';
import { Spinner } from 'shared/ui/Spinner';

import { User } from './ui/user';

export const Friends = () => {
  const { user } = useUser();

  const isMobile = useMaxWidthMediaQuery('md');

  const { data: friends, isPending: isPendingFriends } = useUserFriendsQuery(user?._id);

  return (
    <AnimatedRoute className={twMerge('flex max-h-dvh flex-col overflow-hidden pt-20', isMobile && 'h-dvh')}>
      <div className="flex flex-1 flex-col gap-6 py-6">
        {friends?.map((user) => <User key={user._id} user={user} />)}

        {isPendingFriends && (
          <div className="flex flex-1 items-center justify-center">
            <Spinner className="size-10 text-primary-800" />
          </div>
        )}

        {!isPendingFriends && !friends?.length && (
          <div className="flex flex-1 flex-col justify-center">
            <p className="text-center text-base font-light text-corduroy-600">
              Invite as many friends
              <br />
              as possible to get energy
            </p>
          </div>
        )}
      </div>
      {!isPendingFriends && <InviteFriendsButton />}
    </AnimatedRoute>
  );
};
