import { useState } from 'react';

import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { useEnergyHistoryQuery } from 'shared/api/energy-history/get-energy-history';
import { usePointsHistoryQuery } from 'shared/api/points-history/get-points-history';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { AnimatedRoute } from 'shared/ui/AnimatedRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { Tabs } from 'shared/ui/tabs';

export const Energy = () => {
  const { user } = useUser();
  const [selectedTab, setSelectedTab] = useState<'energy' | 'points'>('energy');

  const isMobile = useMaxWidthMediaQuery('md');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading: isEnergyLoading,
  } = useEnergyHistoryQuery({ limit: 10, userId: user?._id }, { enabled: !!user?._id });

  const {
    data: pointsHistory,
    fetchNextPage: fetchNextPointsPage,
    hasNextPage: hasNextPointsPage,
    isFetching: isPointsFetching,
    isLoading: isPointsLoading,
  } = usePointsHistoryQuery({ limit: 10, userId: user?._id }, { enabled: !!user?._id });

  const historyList = data?.pages.flat() || [];
  const pointsHistoryList = pointsHistory?.pages.flat() || [];

  if (!user) return null;

  return (
    <AnimatedRoute className={twMerge('grid max-h-dvh overflow-hidden pt-20', isMobile && 'h-dvh')}>
      <Tabs.Root
        className="mb-0 flex w-full flex-col overflow-x-scroll scrollbar-none"
        classNameContent="flex-1 overflow-y-auto pr-2"
        onValueChange={setSelectedTab}
        tabs={
          <Tabs.List className="mb-4 w-full min-w-max flex-none">
            <Tabs.Trigger className="w-max" key="energy" value="energy">
              Energy
            </Tabs.Trigger>
            <Tabs.Trigger className="w-max" key="points" value="points">
              Points
            </Tabs.Trigger>
          </Tabs.List>
        }
        value={selectedTab}
      >
        <Tabs.Content key="energy-data" value="energy">
          <div className="mb-5 flex flex-col items-center justify-center gap-1">
            <h1 className="text-sm font-light text-corduroy-700">Your energy balance</h1>
            <div className="flex items-center gap-1 text-xl">
              <Icon className="size-5 text-yellow-500" name="boltSolid" /> {user.energy}
            </div>
          </div>

          <div className="grid grid-rows-[1.5rem_1fr] gap-2 ">
            <h1 className="text-base">Energy History</h1>

            {isEnergyLoading && (
              <div className="mt-3 flex justify-center">
                <Spinner className="size-8" />
              </div>
            )}

            <div className="grid grid-cols-1">
              {historyList.map((item) => {
                return (
                  <div className="flex items-center justify-between gap-3 py-1.5" key={item._id}>
                    <div className="w-28 text-xs font-light text-corduroy-600">
                      {DateTime.fromMillis(item.timestamp).toLocaleString({
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                      {/* {DateTime.fromMillis(item.timestamp).toFormat("dd/MM HH:mm")} */}
                    </div>
                    <div className="flex-1 text-xs font-light">{item.reason}</div>
                    <div className="flex items-center gap-0.5">
                      <span className="text-base font-bold leading-none text-corduroy-800">
                        {item.amount > 0 ? `+${item.amount}` : item.amount}
                      </span>
                      <Icon className="size-4 text-yellow-500" name="boltSolid" />
                    </div>
                  </div>
                );
              })}

              {hasNextPage && (
                <div className="mt-2 flex items-center justify-center">
                  <Button isLoading={isFetching} onClick={() => fetchNextPage()}>
                    Load more
                  </Button>
                </div>
              )}
            </div>
          </div>
          {/* <div className="flex items-start justify-center gap-4"> */}
        </Tabs.Content>

        <Tabs.Content key="points-data" value="points">
          <div className="mb-5 flex flex-col items-center justify-center gap-1">
            <h1 className="text-sm font-light text-corduroy-700">Your points balance</h1>
            <div className="flex items-center gap-1 text-xl">
              <Icon className="size-5 text-yellow-500" name="starSolid" /> {user.points}
            </div>
          </div>

          <div className="grid h-full grid-rows-[1.5rem_1fr] gap-2 ">
            <h1 className="text-base">Points History</h1>

            {isPointsLoading && (
              <div className="mt-3 flex justify-center">
                <Spinner className="size-8" />
              </div>
            )}

            <div className="grid h-full grid-cols-1">
              {pointsHistoryList.map((item) => {
                return (
                  <div className="flex items-center justify-between gap-3 py-1.5" key={item._id}>
                    <div className="w-28 text-xs font-light text-corduroy-600">
                      {DateTime.fromMillis(item.timestamp).toLocaleString({
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                      {/* {DateTime.fromMillis(item.timestamp).toFormat("dd/MM HH:mm")} */}
                    </div>
                    <div className="flex-1 text-xs font-light">{item.reason}</div>
                    <div className="flex items-center gap-0.5">
                      <span className="text-base font-bold leading-none text-corduroy-800">
                        {item.amount > 0 ? `+${item.amount}` : item.amount}
                      </span>
                      <Icon className="size-4 text-yellow-500" name="starSolid" />
                    </div>
                  </div>
                );
              })}

              {hasNextPointsPage && (
                <div className="mt-2 flex items-center justify-center">
                  <Button isLoading={isPointsFetching} onClick={() => fetchNextPointsPage()}>
                    Load more
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </AnimatedRoute>
  );
};
