import { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { createRoot } from 'react-dom/client';

import { App } from './app/App.tsx';

import './index.css';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>,
);

// "nesa.js": "git+https://github.com/nesaorg/nesa.js#a4a03cf7d588828f18cbe3ca1b79ac548c2ef77b",
