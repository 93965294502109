import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import type { EnergyHistoryItem, EnergyHistoryRequestParams, EnergyHistoryResponse } from './types';

import { energyHistoryQueryKeys } from './query-keys';

export const useEnergyHistoryQuery = (
  { limit, userId }: EnergyHistoryRequestParams,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: EnergyHistoryItem[] = [], all: EnergyHistoryItem[][]) =>
      last.length === limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosTGInstance.get<EnergyHistoryResponse>(`/energy-history/users/${userId}`, {
        params: {
          limit,
          skip: pageParam * limit,
        },
        signal,
      });

      return res.data.list;
    },
    queryKey: energyHistoryQueryKeys.list({ limit, userId }),
    ...(options || {}),
  });
};
