import type { ModelType } from 'features/ChatInput/ChatInput';

import { Modal } from 'shared/ui/Modal';

interface Props {
  isOpen: boolean;
  modelType: ModelType;
  onOpenChange: (open: boolean) => void;
  onSelect: (text: string) => void;
}

const EXAMPLES = [
  {
    id: '1',
    text: 'Example one',
  },
  {
    id: '2',
    text: 'Example two',
  },
];

export const CoachmarkModal = ({ isOpen, onOpenChange, onSelect }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Title className="mb-0 font-medium">Example questions</Modal.Title>
        <p className="mb-6 text-sm font-light text-corduroy-600">Use one by selecting it below</p>

        <div className="flex flex-col gap-2">
          {EXAMPLES.map(({ id, text }) => {
            return (
              <div
                className="w-full cursor-pointer rounded-xl rounded-br-none bg-primary-800 px-3 py-1.5 text-white transition-colors hover:bg-primary-800"
                key={id}
                onClick={() => onSelect(text)}
              >
                {text}
              </div>
            );
          })}
        </div>
      </Modal.Content>
    </Modal>
  );
};
