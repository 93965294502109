import type { TGUser } from 'shared/api/user/types';

import { useAvatarLinkQuery } from 'shared/api/telegram';

type Props = {
  user: TGUser;
};

export const User = ({ user }: Props) => {
  const { data } = useAvatarLinkQuery(user?.telegram_id);
  return (
    <div className="flex items-center gap-4">
      <div className="size-12 overflow-hidden rounded-full">
        {data?.url ? (
          <img alt="" className="size-12" src={data.url} />
        ) : (
          <div className="flex size-12 items-center justify-center bg-primary-200 text-base font-semibold uppercase text-primary-800">
            {user.first_name?.[0] || ''}
            {user.last_name?.[0] || ''}
          </div>
        )}
      </div>
      <div className=" flex flex-col">
        <div className="text-sm text-corduroy-900">
          {user.first_name} {user.last_name}
        </div>
        <div className="text-xs text-corduroy-500">@{user?.username}</div>
      </div>
    </div>
  );
};
