import type { Model } from 'shared/api/models/types';

import type { QueryParams } from '../types';

import { getIsZkAvailable } from './getIsZkAvailable';

export const getModelParams = (queryParams: QueryParams, model: Model | undefined) => {
  if (!model) return {};

  const modelName = model.name.toLowerCase();
  const isApiModel = modelName.includes('gpt') || modelName.includes('claude');

  if (model.type === 'text-generation' && !isApiModel) {
    const isZkAvailable = getIsZkAvailable(model.name);

    return {
      max_new_tokens: queryParams.maxNewTokens || 1,
      num_beams: queryParams.numBeams || 1,
      system_prompt: queryParams.systemPrompt || null,
      temperature: queryParams.temperature || 1,
      top_k: queryParams.topK || 0,
      top_p: queryParams.topP || 1,
      ...(isZkAvailable ? { use_zk: queryParams.useZk ?? false } : {}),
    };
  }

  if (model.type === 'text-generation' && isApiModel) {
    return {
      api_key: queryParams.apiKey || null,
      frequency_penalty: queryParams.frequencyPenalty || 0,
      max_tokens: queryParams.maxTokens,
      presence_penalty: queryParams.presencePenalty || 0,
      stop_sequence: queryParams.stopSequence || null,
      temperature: queryParams.temperature || 1,
      top_p: queryParams.topP || 1,
    };
  }

  return {
    frequency_penalty: queryParams.frequencyPenalty || 0,
    presence_penalty: queryParams.presencePenalty || 0,
    temperature: queryParams.temperature || 1,
    top_p: queryParams.topP || 1,
  };
};
