import { useMutation, useQuery } from '@tanstack/react-query';

import type { UseMutationOptions, UseQueryOptions } from 'shared/types';

import { axiosTGInstance, setupInterceptors } from 'shared/config/axiosInstance';

import type { TGUser } from './types';

import { userQueryKeys } from './queryKeys';

export const useCheckCodeMutation = (
  options: UseMutationOptions<{ status: boolean }, Error, { code: string }> = {},
) => {
  return useMutation({
    mutationFn: async ({ code }) => {
      const { data } = await axiosTGInstance.get<{ status: boolean }>(`/user/check-auth-code/${code}`);

      return data;
    },
    ...options,
  });
};

export const useMeQuery = (options: UseQueryOptions<TGUser, Error, TGUser> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosTGInstance.get<TGUser>(`/user/web-me`, {
        signal,
      });

      return data;
    },
    queryKey: userQueryKeys.me(),
    ...options,
  });
};

export const useAuthByCodeMutation = (options: UseMutationOptions<TGUser, Error, { code: string }> = {}) => {
  return useMutation({
    mutationFn: async ({ code }) => {
      const { data } = await axiosTGInstance.post<{ access_token: string; user: TGUser }>(`/user/auth-code`, {
        authCode: code,
      });
      setupInterceptors(data?.access_token);

      return data.user;
    },
    ...options,
  });
};
