import { Portal } from '@radix-ui/react-portal';
import { AnimatePresence, motion } from 'framer-motion';

import { useShortListModelsQuery } from 'shared/api/models/useShortListModelsQuery';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onModelChange: (model: string) => void;
  selectedModel: null | string;
  type: 'image' | 'text';
};

const SIDEBAR_WIDTH = 480;

export const SidebarChatSettings = ({ isOpen, onClose, onModelChange, selectedModel, type }: Props) => {
  const isMobile = useMaxWidthMediaQuery('md');

  const { data: shortListModels, status: shortListModelsStatus } = useShortListModelsQuery();

  const modelsByType = shortListModels
    ? shortListModels[type === 'image' ? 'imageModels' : 'textModels']
    : [];

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              animate={{
                opacity: 1,
                transition: { duration: 0.2 },
              }}
              className="fixed inset-0 z-10 bg-corduroy-900/40"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={() => onClose()}
            />
            <motion.div
              animate={{
                opacity: 1,
                transition: { duration: 0.1 },
                translateX: isMobile ? '0%' : 0,
              }}
              className="fixed inset-y-0 right-0 z-20 w-full bg-white md:w-auto md:min-w-[30rem]"
              exit={{ translateX: isMobile ? '100%' : SIDEBAR_WIDTH }}
              initial={{ translateX: isMobile ? '100%' : SIDEBAR_WIDTH }}
            >
              <h1 className="relative px-5 py-6 text-center text-2xl font-semibold text-corduroy-900">
                {type === 'image' ? 'Image Settings' : 'Text Settings'}

                <button
                  className=" absolute right-5 size-6 cursor-pointer text-corduroy-900 outline-none duration-200 hover:text-primary-800"
                  onClick={() => onClose()}
                >
                  <Icon className="size-6" name="close" />
                </button>
              </h1>
              <div className="mt-2 px-5">
                {shortListModelsStatus === 'success' && (
                  <div className="rounded-2xl bg-primary-40 p-5">
                    <div className="mb-6 text-base font-medium text-corduroy-600">Model</div>
                    <div className="flex items-center justify-between">
                      <div className="text-sm text-corduroy-900">Select Model</div>
                      <Select
                        className="max-w-44"
                        onValueChange={onModelChange}
                        placeholder="Select model"
                        size="small"
                        value={selectedModel || undefined}
                        valueClassName="line-clamp-1 whitespace-nowrap"
                      >
                        <Select.Content className="p-4">
                          <div className="mb-3 text-sm font-medium text-corduroy-600">Selected Model</div>
                          {modelsByType.map((el) => (
                            <Select.Item className="rounded-lg" key={el._id} value={el.name}>
                              {el.name}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};
