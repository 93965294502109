import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

import type { ModelType } from 'features/ChatInput/ChatInput';

import logoSrc from 'app/assets/images/logo.svg';
import { ChatInput } from 'features/ChatInput';
import { CoachmarkModal } from 'features/CoachmarkModal';
import { createChat, createMessage } from 'shared/hooks/useDb';
import { useStateX } from 'shared/hooks/useStateX';
import { AnimatedRoute } from 'shared/ui/AnimatedRoute';

import 'react-toastify/dist/ReactToastify.css';

export const Start = () => {
  const navigate = useNavigate();

  const [state, setState] = useStateX<{ modelType: ModelType; selectedModel: null | string; value: string }>({
    modelType: 'text',
    selectedModel: null,
    value: '',
  });

  const [isCoachmarkOpen, setIsCoachmarkOpen] = useState(false);

  const { mutateAsync } = useMutation({ mutationFn: createChat });
  const { mutateAsync: addMessage } = useMutation({ mutationFn: createMessage });

  const handleSend = async () => {
    try {
      const chatId = await mutateAsync({
        modelType: state.modelType,
        wallet: undefined,
      });
      const message = await addMessage({ chatId, content: state.value, role: 'user' });

      navigate(`/chat/${chatId}`, {
        state: { message, modelType: state.modelType, selectedModel: state.selectedModel },
      });
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong');
    }
  };

  return (
    <AnimatedRoute className="my-auto flex h-dvh w-full flex-col items-center justify-center gap-5">
      <img className="size-20" src={logoSrc} />
      {/* <Icon className="size-14 text-black" name="logo" /> */}
      <div className="mb-2 flex flex-col justify-center text-center text-2xl font-light">
        <h1 className="mb-2">Welcome to Private & Uncensored AI</h1>
        <p className="mx-auto max-w-md text-sm/4 font-light text-corduroy-600 md:text-base/5">{`I don't have restrictions like others bots do, so ask anything you want to know or see `}</p>
      </div>

      <ChatInput
        onChange={(e) => {
          setState({ value: e.target.value });
        }}
        onCoachmarkClick={() => setIsCoachmarkOpen(true)}
        onModelTypeChange={(type) => setState({ modelType: type })}
        onSelectedModelChange={(selectedModel) => setState((prev) => ({ ...prev, selectedModel }))}
        onSend={handleSend}
        selectedModel={state.selectedModel}
        type={state.modelType}
        value={state.value}
      />

      <CoachmarkModal
        isOpen={isCoachmarkOpen}
        modelType={state.modelType}
        onOpenChange={setIsCoachmarkOpen}
        onSelect={(text) => {
          setState({ value: text });
          setIsCoachmarkOpen(false);
        }}
      />
    </AnimatedRoute>
  );
};
