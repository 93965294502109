import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import type { EnergyHistoryResponse, PointsHistoryItem, PointsHistoryRequestParams } from './types';

import { pointsHistoryQueryKeys } from './query-keys';

export const usePointsHistoryQuery = (
  { limit, userId }: PointsHistoryRequestParams,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: PointsHistoryItem[] = [], all: PointsHistoryItem[][]) =>
      last.length === limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosTGInstance.get<EnergyHistoryResponse>(`/points-history/users/${userId}`, {
        params: {
          limit,
          skip: pageParam * limit,
        },
        signal,
      });

      return res.data.list;
    },
    queryKey: pointsHistoryQueryKeys.list({ limit, userId }),
    ...(options || {}),
  });
};
