import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useLayoutEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import type { TGUser } from 'shared/api/user/types';

import { useMeQuery } from 'shared/api/user/auth';
import { useUserSignUpMutation } from 'shared/api/user/useUserSignUpMutation';
import { getUser, saveUser } from 'shared/hooks/useDb';

export type LocalUser = {
  _id: string;
  created_at?: number;
  energy?: number;
  points?: number;
};

export type UserProps = {
  isFetchedUser: boolean;
  localUser: LocalUser | null;
  setUser: Dispatch<SetStateAction<TGUser | null>>;
  tgUserStatus: 'error' | 'pending' | 'success';
  user: TGUser | null;
};

export const UserContext = createContext<UserProps | null>(null);

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<TGUser | null>(null);
  const [isFetchedUser, setIsFetchedUser] = useState(false);

  const { mutateAsync: userSignUp } = useUserSignUpMutation();

  const { data: tgUser, status: tgUserStatus } = useMeQuery();

  const handleGetUser = async () => {
    const user = await getUser();

    if (user) return user;

    const { user: createdUser } = await userSignUp();

    await saveUser(createdUser);

    return createdUser || null;
  };

  const { data: localUser } = useQuery({
    enabled: tgUserStatus === 'error',
    queryFn: handleGetUser,
    queryKey: ['user'],
  });

  useLayoutEffect(() => {
    if (tgUser) {
      setUser(tgUser);
    }
  }, [tgUser]);

  useLayoutEffect(() => {
    if (tgUserStatus === 'error' || tgUserStatus === 'success') {
      setIsFetchedUser(true);
    }
  }, [tgUserStatus]);

  return (
    <UserContext.Provider
      value={{
        isFetchedUser,
        localUser: localUser || null,
        setUser,
        tgUserStatus,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const state = useContext(UserContext);

  if (!state) throw new Error('use hook without provider');

  return state;
};
