import { useRef } from 'react';
import { toast } from 'react-toastify';

import copy from 'copy-to-clipboard';

import { useUser } from 'app/stores/user';
import { useGetInviteFriendsQuery } from 'shared/api/invite-friends';
import { useBotInfoQuery } from 'shared/api/telegram';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

export const InviteFriendsButton = () => {
  const { user } = useUser();

  const toastId = useRef<number | string>();

  const { data: info, isPending: isPendingIfo } = useBotInfoQuery();

  const { data: invite, isPending: isPendingInvite } = useGetInviteFriendsQuery(user?._id);

  return (
    <div className="flex flex-col items-center gap-2 pb-2">
      <Button
        className=" w-full max-w-96"
        isLoading={isPendingInvite || isPendingIfo}
        onClick={() => {
          if (info && invite) {
            toastId.current = toast.success(
              'The invite link has been copied. Send it to a friend to get energy.',
              { toastId: toastId.current, updateId: toastId.current },
            );
            copy(`https://t.me/${info.username}/shhfun?startapp=invite=${invite._id}`);
          } else {
            toast.error('Error...');
          }
        }}
        size="sm"
      >
        <Icon className="relative ml-1 size-6 -translate-x-2" name="copy" />
        <span>Invite a friend</span>
      </Button>
      <p className="text-center text-xs font-light text-corduroy-600">
        100 energy per friend + 30% of the points they generate
      </p>
    </div>
  );
};
