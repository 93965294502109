import type { PropsWithChildren } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { GrazProvider } from 'graz';

import { Header } from 'features/Header';
import { Sidebar } from 'features/Sidebar/Sidebar';
import { Chat } from 'pages/Chat';
import { Start } from 'pages/Start';
import { Energy } from 'pages/energy';
import { Friends } from 'pages/friends';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { Tooltip } from 'shared/ui/Tooltip';

import { SidebarContextProvider } from './stores/sidebar';
import { UserContextProvider, useUser } from './stores/user';

import './App.css';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GrazProvider grazOptions={{ autoReconnect: false, chains: [nesaTestnet] }}>
        <SidebarContextProvider>
          <UserContextProvider>
            <Tooltip.Provider>
              <AnimatePresence>
                <Header key="header" />

                <div className="flex gap-2">
                  <Sidebar />
                  <Routes>
                    <Route element={<Start />} path="/" />
                    <Route element={<Chat />} path="/chat/:id" />
                    <Route
                      element={
                        <ProtectedRoute>
                          <Friends />
                        </ProtectedRoute>
                      }
                      path="/friends"
                    />
                    <Route
                      element={
                        <ProtectedRoute>
                          <Energy />
                        </ProtectedRoute>
                      }
                      path="/energy"
                    />
                  </Routes>
                </div>
              </AnimatePresence>
              <ToastContainer />
            </Tooltip.Provider>
          </UserContextProvider>
        </SidebarContextProvider>
      </GrazProvider>
    </QueryClientProvider>
  );
};

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const { isFetchedUser, user } = useUser();

  if (!isFetchedUser) {
    return null;
  }

  if (user) return children;

  return <Navigate to={'/'} />;
};
