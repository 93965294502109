import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import type { TGUser } from './types';

import { userQueryKeys } from './queryKeys';

export const useUserFriendsQuery = <T extends TGUser[]>(
  userId?: string,
  options: UseQueryOptions<TGUser[], unknown, T> = {},
) => {
  return useQuery<TGUser[], unknown, T>({
    ...options,
    enabled: !!userId,
    queryFn: async ({ signal }) => {
      const { data } = await axiosTGInstance.get<TGUser[]>(`user/friends/${userId}`, {
        signal,
      });

      return data;
    },
    queryKey: userQueryKeys.userFriends(userId),
  });
};
