import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import type { InviteFriend } from './types';

import { inviteFriendsQueryKeys } from './query-keys';

export const useGetInviteFriendsQuery = <T extends InviteFriend>(
  userId?: string,
  options: UseQueryOptions<InviteFriend, unknown, T> = {},
) => {
  return useQuery<InviteFriend, unknown, T>({
    ...options,
    enabled: !!userId,
    queryFn: async ({ signal }) => {
      const { data } = await axiosTGInstance.post<InviteFriend>(`/invite-friends/my/${userId}`, undefined, {
        signal,
      });

      return data;
    },
    queryKey: inviteFriendsQueryKeys.byId(userId),
  });
};
