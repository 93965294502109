import {
  type ComponentProps,
  type ElementType,
  type ForwardedRef,
  type PropsWithChildren,
  forwardRef,
} from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName, PolymorphicProps } from 'shared/types';

import { Spinner } from '../Spinner';

export type ButtonVariant = 'filled' | 'filled-light';
export type ButtonColor = 'primary' | 'secondary';

export type Props = {
  asIcon?: boolean;
  color?: ButtonColor;
  isLoading?: boolean;
  size?: 'sm';
  variant?: ButtonVariant;
} & ClassName;

const Button = <E extends ElementType = 'button'>(
  props: PropsWithChildren<PolymorphicProps<E, Props>>,
  ref: ForwardedRef<E>,
) => {
  const {
    as: Component = 'button',
    asIcon,
    children,
    className,
    color = 'primary',
    disabled,
    isLoading,
    size = 'sm',
    variant = 'filled',
    ...restProps
  } = props;

  const isLocked = disabled || isLoading;

  const isSecondaryLightFilled = color === 'secondary' && variant === 'filled-light';
  const isPrimaryLightFilled = color === 'primary' && variant === 'filled-light';
  const isPrimaryFilled = color === 'primary' && variant === 'filled';
  const isSecondaryFilled = color === 'secondary' && variant === 'filled';

  const iconButtonSize = {
    sm: 'size-10',
  }[size];
  const buttonSize = {
    sm: 'h-10 px-6 py-2 text-xs lg:text-sm 2xl:h-10',
  }[size];

  return (
    <Component
      className={twMerge(
        'relative inline-flex cursor-pointer items-center justify-center gap-1 rounded-full border border-transparent text-center font-medium transition-all focus:outline-none',
        isLocked && 'pointer-events-none cursor-not-allowed',
        isLocked && 'opacity-60',

        asIcon ? iconButtonSize : buttonSize,

        isSecondaryLightFilled && 'border-corduroy-100 bg-corduroy-100 text-ebony-900 hover:brightness-95',
        isPrimaryFilled &&
          'border-primary-800 bg-primary-800 text-white hover:border-primary-800 hover:brightness-95',
        isPrimaryLightFilled && 'border-primary-100 bg-primary-100 text-primary-800 hover:brightness-105',
        isSecondaryFilled &&
          'border-steel-900 bg-steel-900 text-white hover:border-steel-800 hover:bg-steel-800',

        className,
      )}
      disabled={disabled || isLoading}
      ref={ref as ComponentProps<typeof Component>['ref']}
      {...restProps}
    >
      {isLoading ? <Spinner className="size-4" /> : children}
    </Component>
  );
};

const ForwardButton = forwardRef(Button) as typeof Button;

export { ForwardButton as Button };
