export const getErrorMessage = (error: string) => {
  if (error.includes('agent not found by model name')) {
    return { text: 'Model is not supported. Please try another one.', type: 'agentNotFound' };
  }

  if (error.includes('request rejected')) {
    return { text: 'Request rejected', type: 'userRejected' };
  }

  if (error.includes(' does not exist on chain')) {
    return {
      text: `You don’t have any NES in your wallet. Go to the Faucet to get some.`,
      type: 'existsOnChain',
    };
  }

  if (error.includes('insufficient coin balance')) {
    return {
      text: 'Your wallet needs more tokens. You can get them in the Faucet.',
      type: 'insufficientBalance',
    };
  }

  return { text: 'The network is experiencing high traffic. Please try again later.', type: 'default' };
};

// const data = {
//   'orenguteng/llama-3-8b-lexi-uncensored': {
//     sessionId: '02f8b59b727a74d1c789be65bcc2e12d757fd783f290458a6589152743158941dc',
//     transactionHash: 'D50CA8EEB0D360F1734300492E848E5359DD537C737EE4804E80DC569615FE91',
//   },
//   'yodayo-ai/kivotos-xl-2.0': {
//     sessionId: '02f8b59b727a74d1c789be65bcc2e12d757fd783f290458a6589152743158941dc',
//     transactionHash: '33E3E603422A135788DD66BBA695A13B50F0724AEDE417EAB26035B11D843BE0',
//   },
// };
