import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import { telegramQueryKeys } from './query-keys';

export type BotInfo = {
  /** True, if this user added the bot to the attachment menu */
  added_to_attachment_menu?: true;
  /** User's or bot's first name */
  first_name: string;
  /** Unique identifier for this user or bot. */
  id: number;
  /** True, if this user is a bot */
  is_bot: boolean;
  /** True, if this user is a Telegram Premium user */
  is_premium?: true;
  /** IETF language tag of the user's language */
  language_code?: string;
  /** User's or bot's last name */
  last_name?: string;
  /** User's or bot's username */
  username?: string;
};

export const useBotInfoQuery = <T extends BotInfo>(options: UseQueryOptions<BotInfo, unknown, T> = {}) => {
  return useQuery<BotInfo, unknown, T>({
    ...options,
    queryFn: async ({ signal }) => {
      const { data } = await axiosTGInstance.get<BotInfo>(`/telegram/info`, {
        signal,
      });

      return data;
    },
    queryKey: telegramQueryKeys.info(),
  });
};
