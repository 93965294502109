import type { ReactNode } from 'react';

import { Portal } from '@radix-ui/react-portal';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';

import { Icon } from '../Icon';

type Props = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  title?: ReactNode;
};

const SIDEBAR_WIDTH = 480;

export const Sidebar = ({ children, className, isOpen, onClose, title }: Props) => {
  const isMobile = useMaxWidthMediaQuery('md');

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              animate={{
                opacity: 1,
                transition: { duration: 0.2 },
              }}
              className="fixed inset-0 z-10 bg-corduroy-900/40"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={() => onClose()}
            />
            <motion.div
              animate={{
                opacity: 1,
                transition: { duration: 0.1 },
                translateX: isMobile ? '0%' : 0,
              }}
              className="fixed inset-y-0 right-0 z-20 w-full bg-white md:w-auto md:min-w-[30rem]"
              exit={{ translateX: isMobile ? '100%' : SIDEBAR_WIDTH }}
              initial={{ translateX: isMobile ? '100%' : SIDEBAR_WIDTH }}
            >
              {title && (
                <h1 className="relative mb-2 px-5 py-6 text-center text-2xl font-semibold text-corduroy-900">
                  {title}

                  <button
                    className=" absolute right-5 size-6 cursor-pointer text-corduroy-900 outline-none duration-200 hover:text-primary-800"
                    onClick={() => onClose()}
                  >
                    <Icon className="size-6" name="close" />
                  </button>
                </h1>
              )}
              <div className={twMerge('px-5', className)}>{children}</div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};
