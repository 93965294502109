import { useEffect, useState } from 'react';

import { ChatClient } from 'nesa.js';
import { v4 as uuidv4 } from 'uuid';

import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { useEvent } from 'shared/hooks/useEvent';

type Props = {
  mnemonic: string | undefined;
  modelName: string | undefined;
  onClientChange: (params: { client?: ChatClient; modelName?: string; unmount?: boolean }) => void;
};

export const useChatClient = ({ mnemonic, modelName, onClientChange }: Props) => {
  const [client, setClient] = useState<ChatClient>();

  const handleClientChange = useEvent(onClientChange);

  useEffect(() => {
    if (!mnemonic || !modelName) {
      return;
    }

    const chatUtils = new ChatClient({
      chainInfo: nesaTestnet,
      chatId: uuidv4(),
      mnemonic: mnemonic,
      modelName: modelName,
    });

    handleClientChange({ client: chatUtils, modelName });

    setClient(chatUtils);

    return () => {
      chatUtils?.requestCloseHeartbeat();
      setClient(undefined);
      handleClientChange({ modelName, unmount: true });
    };
  }, [mnemonic, handleClientChange, modelName]);

  return client;
};
