import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

import type { Props as IconProps } from '../Icon/Icon';

import { Icon } from '../Icon/Icon';

type Props = { size?: 'medium' | 'small' } & ComponentProps<'button'> & Pick<IconProps, 'name'>;

export const IconButton = ({ className, name, size = 'small', ...props }: Props) => {
  return (
    <button
      {...props}
      className={twMerge(
        'flex cursor-pointer items-center justify-center rounded-md bg-transparent duration-200 hover:bg-primary-50 disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-50',
        size === 'small' && 'size-6',
        size === 'medium' && 'size-8',
        className,
      )}
    >
      <Icon
        className={twMerge(
          'text-primary-800',
          size === 'small' && 'size-[1.125rem]',
          size === 'medium' && 'start-6',
        )}
        name={name}
      />
    </button>
  );
};
