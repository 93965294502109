import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import { telegramQueryKeys } from './query-keys';

type Response = {
  url: string;
};

export const useAvatarLinkQuery = <T extends Response>(
  tgUserId?: number,
  options: UseQueryOptions<Response, unknown, T> = {},
) => {
  return useQuery<Response, unknown, T>({
    ...options,
    enabled: typeof tgUserId === 'number',
    queryFn: async ({ signal }) => {
      const { data } = await axiosTGInstance.get<Response>(`/telegram/avatar/${tgUserId}`, {
        signal,
      });

      return data;
    },
    queryKey: telegramQueryKeys.byId(tgUserId),
  });
};
