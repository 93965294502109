import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { Model } from './types';

import { modelsKeys } from './queryKeys';

export const useGetModelByIdQuery = (id?: null | string, options: UseQueryOptions<Model> = {}) => {
  return useQuery({
    enabled: !!id,
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Model>(`/models/${id}`, {
        signal,
      });

      return data;
    },
    queryKey: modelsKeys.model({ id }),
    ...options,
  });
};
