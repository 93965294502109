import { type ReactNode, type TextareaHTMLAttributes, useState } from 'react';
import { toast } from 'react-toastify';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';
import type { IconName } from 'shared/ui/Icon';

import { theme } from 'app/theme';
import { SidebarChatSettings } from 'features/SidebarChatSettings';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { IconButton } from 'shared/ui/IconButton';
import { Tooltip } from 'shared/ui/Tooltip';

export type ModelType = 'image' | 'text';

type Props = {
  actionSlot?: ReactNode;
  error?: string;
  isLoading?: boolean;
  onCoachmarkClick?: () => void;
  onModelTypeChange: (type: ModelType) => void;
  onSelectedModelChange: (model: string) => void;
  onSend: () => void;
  selectedModel: null | string;
  type: ModelType;
} & ClassName &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

const CHAT_OPTIONS: { icon: IconName; label: string; value: ModelType }[] = [
  { icon: 'chatDots', label: 'Text Conversation', value: 'text' },
  { icon: 'imagePlus', label: 'Image Generation', value: 'image' },
];

export const ChatInput = ({
  actionSlot,
  className,
  disabled,
  error,
  isLoading,
  // onCoachmarkClick,
  onModelTypeChange,
  onSelectedModelChange,
  onSend,
  rows = 2,
  selectedModel,
  type,
  ...textAreaProps
}: Props) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const isDisabled = disabled || isLoading;
  const [isInteractedWithImage, setInteractedWithImage] = useLocalStorage('image-switch-interaction', false);

  return (
    <div className={twMerge('relative flex w-full flex-col gap-0 pb-4', className)}>
      <div
        className={twMerge(
          'rounded-xl border border-corduroy-100 bg-white p-2 text-sm font-light outline-none transition-colors md:p-4',
          'placeholder:font-light hover:border-corduroy-300 focus:border-corduroy-300',
          isDisabled && 'pointer-events-none cursor-not-allowed bg-corduroy-200',
          'flex flex-col',
        )}
      >
        <div className="flex">
          <textarea
            className="w-full resize-none border-none bg-transparent outline-none"
            disabled={isDisabled}
            placeholder={type === 'image' ? 'Generate any image' : 'Ask any question'}
            rows={rows}
            {...textAreaProps}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey == false) {
                onSend();
              }
            }}
          ></textarea>

          <div className="min-w-fit">
            {actionSlot || (
              <Button
                className="size-9 !max-h-9 px-2"
                disabled={!textAreaProps.value}
                isLoading={isLoading}
                onClick={onSend}
              >
                <Icon className="size-full" name="arrowUpLong" />
              </Button>
            )}
          </div>
        </div>

        <div className="flex">
          <div className="relative flex items-center gap-2">
            <div className="relative flex w-fit items-center gap-2 rounded-lg bg-primary-40 p-1">
              {CHAT_OPTIONS.map(({ icon, label, value }) => {
                return (
                  <div
                    className={twMerge(
                      'flex size-7 cursor-pointer items-center justify-center rounded-lg transition-colors hover:bg-primary-100',
                      type === value && 'bg-primary-800 hover:bg-primary-800',
                      isDisabled && 'pointer-events-none opacity-70',
                    )}
                    key={value}
                    onClick={() => {
                      value === 'image' && setInteractedWithImage(true);

                      onModelTypeChange(value);
                    }}
                  >
                    <Tooltip alignOffset={10} content={label} side="bottom">
                      <Icon
                        className={twMerge(
                          'text-white',
                          type !== value && 'text-corduroy-500',
                          icon === 'chatDots' && 'text-transparent',
                        )}
                        name={icon}
                        svgProps={
                          icon === 'chatDots'
                            ? { stroke: type === value ? theme.colors.white : theme.colors.corduroy[500] }
                            : {}
                        }
                      />
                    </Tooltip>
                  </div>
                );
              })}
            </div>
            <IconButton name="settings" onClick={() => setIsOpenSidebar(true)} size="medium" />
            <AnimatePresence>
              {!isLoading && !isInteractedWithImage && (
                <motion.div
                  animate={{
                    opacity: [0, 1, 1, 0],
                    translateY: [10, 0, 0, -10],
                    // opacity: [0, 1, 1, 1, 0],
                    // translateY: [10, 0, 0, -10, -10],
                  }}
                  className="absolute -right-3 flex !min-w-max items-center gap-2 text-xs text-corduroy-500"
                  exit={{ opacity: 0, transition: { duration: 0.5 } }}
                  initial={{ opacity: 0, translateX: '100%' }}
                  transition={{ duration: 6, repeat: Infinity, repeatDelay: 4, times: [0, 0.1, 0.95, 1] }}
                  // transition={{ duration: 6, repeat: Infinity, repeatDelay: 4, times: [0, 0.1, 0.9, 0.95, 1] }}
                >
                  <span className="text-base">👈</span> Switch between text and image generation
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {!isLoading && (
            <motion.div
              animate={{ opacity: 1 }}
              className="absolute bottom-5 right-2 flex !min-w-max cursor-pointer items-center gap-2 text-xs text-[#0784C3] transition-all hover:brightness-75"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={() => {
                toast.info('Coming soon');
                return;
                // onCoachmarkClick?.();
              }}
              transition={{ duration: 0.2 }}
              // transition={{ duration: 6, repeat: Infinity, repeatDelay: 4, times: [0, 0.1, 0.9, 0.95, 1] }}
            >
              Not sure what to ask?
            </motion.div>
          )}
        </div>
      </div>
      {error && <div className="">{error}</div>}

      <SidebarChatSettings
        isOpen={isOpenSidebar}
        onClose={() => setIsOpenSidebar(false)}
        onModelChange={onSelectedModelChange}
        selectedModel={selectedModel}
        type={type}
      />
    </div>
  );
};
