import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState } from 'react';

export interface Props {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const SidebarContext = createContext<Props>({
  isOpen: false,
  onOpenChange: () => {},
});

export const SidebarContextProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <SidebarContext.Provider value={{ isOpen, onOpenChange: setIsOpen }}>{children}</SidebarContext.Provider>
  );
};

export const useSidebarState = () => useContext(SidebarContext);
