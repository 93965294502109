import { toast } from 'react-toastify';

import { WalletType, useSuggestChainAndConnect } from 'graz';
import { twMerge } from 'tailwind-merge';

import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';

import cosmostationSrc from './assets/cosmostation.svg';
import keplrSmLogo from './assets/keplrSmLogo.svg';
import leapSrc from './assets/leap.svg';
import metamaskSrc from './assets/metamask.svg';

interface Props {
  isOpen: boolean;
  onConnected?: () => void;
  onOpenChange: (open: boolean) => void;
}

const baseClassName =
  'flex w-full cursor-pointer items-center gap-4 rounded-xl border border-corduroy-200 px-4 py-3 transition-colors hover:border-primary-200 hover:bg-primary-40';

export const WalletProviderModal = ({ isOpen: open, onConnected, onOpenChange }: Props) => {
  const isKeplrWalletInstalled = !!window.keplr;
  const isLeapWalletInstalled = !!window.leap;
  const isCosmostationWalletInstalled = !!window.cosmostation;
  const isMetaMaskWalletInstalled = !!window?.ethereum?.isMetaMask;

  const { isLoading, suggestAndConnectAsync } = useSuggestChainAndConnect();

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full max-w-[600px]">
        <Modal.CloseButton />
        <Modal.Title className="mb-7 font-medium">Select your wallet provider</Modal.Title>

        <div className="relative grid w-full grid-cols-2 gap-2">
          {isLoading && (
            <div className="absolute inset-0 flex flex-col items-center justify-center gap-3 bg-white/50 text-corduroy-500">
              <Spinner className="size-6" /> <span>Connecting...</span>
            </div>
          )}
          <div
            className={twMerge(
              baseClassName,
              isLoading && 'pointer-events-none select-none opacity-50',
              !isKeplrWalletInstalled && 'pointer-events-none opacity-80',
            )}
            onClick={async () => {
              try {
                if (!window.keplr) {
                  toast.error(`You don't have Keplr wallet installed or enabled`);
                  return;
                }

                await suggestAndConnectAsync({ chainInfo: nesaTestnet, walletType: WalletType.KEPLR });
                onConnected?.();
                onOpenChange(false);
              } catch (e) {
                toast.error('Something went wrong');
              }
            }}
          >
            <img className="w-12 overflow-hidden rounded-md" src={keplrSmLogo} />

            <div className="flex flex-col font-medium text-corduroy-700">
              Keplr
              {!isKeplrWalletInstalled && (
                <div className="mt-0.5 w-full text-left text-xs/none font-normal text-corduroy-400">
                  {`Not detected`}
                </div>
              )}
            </div>
          </div>

          <div
            className={twMerge(
              baseClassName,
              isLoading && 'pointer-events-none select-none opacity-50',
              !isMetaMaskWalletInstalled && 'pointer-events-none opacity-80',
            )}
            onClick={async () => {
              await suggestAndConnectAsync({
                chainInfo: nesaTestnet,
                walletType: WalletType.METAMASK_SNAP_LEAP,
              });

              onConnected?.();
              onOpenChange(false);
            }}
          >
            <img className="w-12 overflow-hidden rounded-md" src={metamaskSrc} />

            <div className="font-medium text-corduroy-700">
              MetaMask
              {!isMetaMaskWalletInstalled && (
                <div className="mt-0.5 w-full text-left text-xs/none font-normal text-corduroy-400">
                  {`Not detected`}
                </div>
              )}
            </div>
          </div>

          <div
            className={twMerge(
              baseClassName,
              isLoading && 'pointer-events-none select-none opacity-50',
              !isLeapWalletInstalled && 'pointer-events-none opacity-80',
            )}
            onClick={async () => {
              await suggestAndConnectAsync({
                chainInfo: nesaTestnet,
                walletType: WalletType.LEAP,
              });

              onConnected?.();
              onOpenChange(false);
            }}
          >
            <img className="w-12 overflow-hidden rounded-md" src={leapSrc} />

            <div className="font-medium text-corduroy-700">
              Leap
              {!isLeapWalletInstalled && (
                <div className="mt-0.5 w-full text-left text-xs/none font-normal text-corduroy-400">
                  {`Not detected`}
                </div>
              )}
            </div>
          </div>
          <div
            className={twMerge(
              baseClassName,
              isLoading && 'pointer-events-none select-none opacity-50',
              !isCosmostationWalletInstalled && 'pointer-events-none opacity-80',
            )}
            onClick={async () => {
              await suggestAndConnectAsync({
                chainInfo: nesaTestnet,
                walletType: WalletType.COSMOSTATION,
              });

              onConnected?.();
              onOpenChange(false);
            }}
          >
            <img className="w-12 overflow-hidden rounded-md" src={cosmostationSrc} />

            <div className="font-medium text-corduroy-700">
              Cosmostation
              {!isCosmostationWalletInstalled && (
                <div className="mt-0.5 w-full text-left text-xs/none font-normal text-corduroy-400">
                  {`Not detected`}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
