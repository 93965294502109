import type { UseCountUpProps } from 'react-countup/build/useCountUp';

import { useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';

export const useCustomCountUp = <T extends HTMLElement = HTMLElement>(
  value: number,
  acceptUpdate = true,
  params?: Partial<UseCountUpProps>,
) => {
  const initialValueRef = useRef(value);
  const ref = useRef<T>(null);

  const countUp = useCountUp({
    end: value,
    ref: ref,
    start: initialValueRef.current,
    ...params,
  });

  const update = countUp.update;

  useEffect(() => {
    if (acceptUpdate) {
      setTimeout(() => {
        console.log(value);
        console.log(ref);
        update(value);
      }, 1000);
    }
  }, [acceptUpdate, value, update]);

  return [ref, countUp] as const;
};
