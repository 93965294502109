import type { QueryParams } from './types';

export const defaultQueryParams: QueryParams = {
  context: undefined,
  frequencyPenalty: 0,
  presencePenalty: 0,
  question: '',
  temperature: 1,
  topP: 1,
};
