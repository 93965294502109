import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { Chat } from 'shared/hooks/useDb';

import { queryClient } from 'app/App';
import { useSidebarState } from 'app/stores/sidebar';
import { useDeleteChatMutation } from 'shared/api/chat/useDeleteChatMutation';
import { getAllChats, updateChat } from 'shared/hooks/useDb';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Spinner } from 'shared/ui/Spinner';

const SIDEBAR_WIDTH = 340;

export const Sidebar = () => {
  const navigate = useNavigate();

  const { isOpen, onOpenChange } = useSidebarState();

  const isMobile = useMaxWidthMediaQuery('md');

  const [chatToEdit, setChatToEdit] = useState<Chat>();

  const { data, isLoading } = useQuery({
    queryFn: getAllChats,
    queryKey: ['allChats'],
  });

  const { isPending: deleteChatLoading, mutate: deleteChat } = useDeleteChatMutation();

  const handleChatSave = (chatToSave: Chat) => {
    if (!chatToSave?.chatName) return;

    queryClient.setQueryData(['allChats', chatToSave.chatId], chatToSave);
    queryClient.setQueryData(
      ['allChats'],
      data?.map((c) => (c.chatId === chatToSave.chatId ? chatToSave : c)),
    );
    queryClient.invalidateQueries({ queryKey: ['allChats'] });
    updateChat(chatToSave);
    setChatToEdit(undefined);
  };

  const handleDeleteChat = async (chatId: string, isActive: boolean) => {
    await deleteChat({ chatId });
    queryClient.setQueryData(
      ['allChats'],
      data?.filter((c) => c.chatId !== chatId),
    );
    if (isActive) {
      const chat = data?.find((c) => c.chatId !== chatId);
      navigate(chat ? `/chat/${chat.chatId}` : '/');
      onOpenChange(false);
    }
  };

  return (
    <motion.div className="relative">
      <motion.div
        animate={{
          opacity: isOpen ? 1 : 0,
          transition: { duration: 0.2 },
          width: isOpen ? (isMobile ? '100vw' : SIDEBAR_WIDTH) : 0,
        }}
        className={twMerge(
          'relative z-10 h-dvh overflow-hidden rounded-r-xl bg-white',
          isMobile && 'absolute left-0 z-20 h-dvh w-screen',
        )}
        initial={{ opacity: isOpen ? 1 : 0, width: isOpen ? (isMobile ? '100vw' : SIDEBAR_WIDTH) : 0 }}
      >
        <div className="flex max-h-dvh flex-col gap-6 px-4 py-6">
          {isMobile && (
            <Icon
              className="absolute right-4 top-4 cursor-pointer text-corduroy-500 transition-colors hover:text-primary-800"
              name="close"
              onClick={() => onOpenChange(false)}
            />
          )}
          <h1 className="text-lg font-normal">Chats</h1>

          <div className="relative flex flex-col gap-2 overflow-y-scroll">
            {isLoading ? (
              <div className="flex items-center justify-center py-10">
                <Spinner />
              </div>
            ) : (
              data?.map((chat) => {
                const isSelected = chatToEdit?.chatId === chat.chatId;

                return (
                  <NavLink
                    className={({ isActive }) =>
                      twMerge(
                        'flex w-full cursor-pointer items-center rounded-lg px-2.5 py-2 font-light transition-colors hover:bg-primary-40',
                        isSelected && 'bg-primary-100 hover:bg-primary-100',
                        isActive && 'bg-primary-100 text-primary-800 hover:bg-primary-100',
                      )
                    }
                    key={chat.chatId}
                    onClick={() => {
                      isMobile && onOpenChange(false);
                    }}
                    to={`/chat/${chat.chatId}`}
                  >
                    {({ isActive }) => (
                      <>
                        {chatToEdit?.chatId === chat.chatId ? (
                          <Input
                            classNameInputWrapper="px-0 h-5 border-none bg-transparent"
                            endSlot={
                              <div className="flex items-center gap-1">
                                <Icon
                                  className="size-4 text-corduroy-800 transition-all hover:text-red-800"
                                  name="close"
                                  onClick={() => setChatToEdit(undefined)}
                                />
                                <Icon
                                  className={twMerge(
                                    'size-4 text-green-800 transition-all hover:brightness-90',
                                    !chatToEdit.chatName && 'cursor-not-allowed text-corduroy-300',
                                  )}
                                  name="check"
                                  onClick={() => handleChatSave(chatToEdit)}
                                />
                              </div>
                            }
                            onChange={(e) => setChatToEdit({ ...chat, chatName: e.target.value || '' })}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && !!chatToEdit.chatName) {
                                handleChatSave(chatToEdit);
                              }
                            }}
                            placeholder="Chat name"
                            value={
                              chatToEdit.chatName === undefined
                                ? `Chat-${chat.chatId}`
                                : chatToEdit.chatName || ''
                            }
                          />
                        ) : (
                          <span className="truncate">{chat.chatName || `Chat-${chat.chatId}`}</span>
                        )}
                        {chatToEdit?.chatId !== chat.chatId && (
                          <div className="flex gap-1">
                            <div
                              className="ml-auto flex"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                setChatToEdit(chat);
                              }}
                            >
                              <Icon
                                className="size-4 text-corduroy-500 transition-colors hover:text-primary-800"
                                name="edit"
                              />
                            </div>
                            {!deleteChatLoading && (
                              <Icon
                                className="size-4 text-corduroy-800 transition-all hover:text-red-800"
                                name="trash"
                                onClick={() => handleDeleteChat(chat.chatId, isActive)}
                              />
                            )}
                            {deleteChatLoading && <Spinner className="size-4" />}
                          </div>
                        )}
                      </>
                    )}
                  </NavLink>
                );
              })
            )}
          </div>
        </div>
      </motion.div>

      <div
        className="group absolute -right-6 top-1/2  flex h-dvh w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-r-lg pl-1.5 transition-colors hover:bg-primary-50"
        onClick={() => onOpenChange(!isOpen)}
      >
        <Icon
          className={twMerge(
            'text-corduroy-600 transition-transform group-hover:text-primary-800',
            !isOpen && '-rotate-90',
            isOpen && 'rotate-90',
          )}
          name="arrowDownSm"
        />
      </div>
    </motion.div>
  );
};
