import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { deleteMessage } from 'shared/hooks/useDb';

type Params = {
  messageId: string;
};

export const useDeleteMessageMutation = (options: UseMutationOptions<unknown, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async ({ messageId }: Params) => {
      return deleteMessage(messageId);
    },
    ...options,
  });
};
