import { useQuery } from '@tanstack/react-query';

import type { Chat } from 'shared/hooks/useDb';
import type { UseQueryOptions } from 'shared/types';

import { getChatById } from 'shared/hooks/useDb';

import { chatKeys } from './queryKeys';

export const useChatByIdQuery = (chatId: string, options: UseQueryOptions<Chat | undefined> = {}) => {
  return useQuery({
    queryFn: async () => {
      return getChatById(chatId);
    },
    queryKey: chatKeys.chatId(chatId),
    ...options,
  });
};
