import type { PropsWithChildren, ReactNode } from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { twMerge } from 'tailwind-merge';

type Props = {
  content?: ReactNode;
} & Omit<TooltipPrimitive.TooltipContentProps, 'content'> &
  Omit<TooltipPrimitive.TooltipProps, 'content'>;

export const Tooltip = ({
  children,
  className,
  content,
  defaultOpen,
  delayDuration = 50,
  disableHoverableContent,
  onOpenChange,
  open,
  ...props
}: PropsWithChildren<Props>) => {
  if (!content) return children;

  return (
    <TooltipPrimitive.Root
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
      onOpenChange={onOpenChange}
      open={open}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          align="center"
          side="right"
          sideOffset={4}
          {...props}
          className={twMerge(
            'z-30 rounded-full bg-corduroy-200 px-2 py-0.5 text-xs font-normal text-corduroy-800 shadow-sm',
            className,
          )}
        >
          {content}
          {/* <TooltipPrimitive.Arrow className="fill-corduroy-800" height={5} width={11} /> */}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};

Tooltip.Provider = TooltipPrimitive.Provider;
