import { useMutation } from '@tanstack/react-query';

import type { ModelType } from 'features/ChatInput/ChatInput';
import type { UseMutationOptions } from 'shared/types';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import type { TGUser } from './types';

type PreMessageResponse = {
  user: TGUser;
};

type Params = {
  modelName: string;
  modelType: ModelType;
};

export const usePreMessageMutation = (
  userId: string,
  options: UseMutationOptions<PreMessageResponse, Error, Params> = {},
) => {
  return useMutation({
    mutationFn: async ({ modelName, modelType }: Params) => {
      const { data } = await axiosTGInstance.post<PreMessageResponse>(`/user/${userId}/message`, {
        modelName,
        modelType,
        platform: 'web',
      });

      return data;
    },
    ...options,
  });
};
