import { useQuery } from '@tanstack/react-query';

import type { Message } from 'shared/hooks/useDb';
import type { UseQueryOptions } from 'shared/types';

import { getMessagesByChatId } from 'shared/hooks/useDb';

import { messagesKeys } from './queryKeys';

export const useChatMessagesQuery = (chatId: string, options: UseQueryOptions<Message[]> = {}) => {
  return useQuery({
    queryFn: async () => {
      const messages = await getMessagesByChatId(chatId);

      return messages.sort((a, b) => -a.timestamp + b.timestamp);
    },
    queryKey: messagesKeys.chatId(chatId),
    ...options,
  });
};
