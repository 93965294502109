import { toast } from 'react-toastify';

import { useQuery } from '@tanstack/react-query';

import type { ModelType } from 'features/ChatInput/ChatInput';

import { queryClient } from 'app/App';
import { useUser } from 'app/stores/user';
import { walletKeys } from 'shared/api/wallet/queryKeys';
import { useCreateNesaWallet } from 'shared/api/wallet/useCreateNesaWallet';

import type { UserWallet } from './useDb';

export const useWallet = (modelType: ModelType) => {
  const { user } = useUser();

  const walletQueryKey = walletKeys.byType(modelType);

  const { isPending: isCreatingWallet, mutateAsync: createNesaWallet } = useCreateNesaWallet({});

  const handleGetWallet = async () => {
    if (!user) {
      toast.error('No user');

      return null;
    }

    const createdWallet = await createNesaWallet({ modelType: modelType, userId: user?._id });

    queryClient.setQueryData(walletQueryKey, createdWallet);

    return (createdWallet.wallet as unknown as UserWallet) || null;
  };

  const { data: wallet, isPending: isLoadingWallet } = useQuery({
    enabled: !!user,
    queryFn: handleGetWallet,
    queryKey: walletKeys.byType(modelType),
  });

  return {
    isCreatingWallet,
    isLoadingWallet,
    wallet,
  };
};
