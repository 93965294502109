import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { deleteChat } from 'shared/hooks/useDb';

type Params = {
  chatId: string;
};

export const useDeleteChatMutation = (options: UseMutationOptions<unknown, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async ({ chatId }: Params) => {
      return deleteChat(chatId);
    },
    ...options,
  });
};
