import type { DirectSecp256k1HdWallet } from '@cosmjs/proto-signing';

import { useMutation } from '@tanstack/react-query';

import type { ModelType } from 'features/ChatInput/ChatInput';
import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type CreateWalletResponse = { wallet: DirectSecp256k1HdWallet };
type CreateWalletParams = { modelType: ModelType; userId: string };

export const useCreateNesaWallet = (
  options: UseMutationOptions<CreateWalletResponse, Error, CreateWalletParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: CreateWalletParams) => {
      const { data } = await axiosInstance.post<CreateWalletResponse>(`/wallets/assign`, params, {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_WALLET_SERVICE_KEY}`,
        },
      });

      return data;
    },
    ...options,
  });
};
