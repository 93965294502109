export const theme = {
  colors: {
    black: '#000000',
    corduroy: {
      50: '#FAFAFC',
      100: '#EEEFF3',
      200: '#E2E4EB',
      300: '#C8CAD0',
      400: '#ADB0B6',
      500: '#8E9197',
      600: '#74777D',
      700: '#5C5F64',
      800: '#393C42',
      900: '#1A1C20',
    },
    ebony: {
      900: '#090B15',
    },
    green: {
      800: '#1BC085',
    },
    pink: {
      500: '#FF5473',
    },
    primary: {
      40: '#FFEDF0',
      50: '#FFE5E9',
      100: '#FFD9DF',
      200: '#FFCCD5',
      300: '#FFA1B2',
      800: '#EF183D',
    },
    red: {
      800: '#FD5A5A',
    },
    steel: {
      50: '#F4F5F9',
      800: '#3F315E',
      900: '#2A213E',
      1000: '#171123',
    },
    transparent: 'transparent',
    white: '#FFFFFF',
    yellow: {
      500: '#FF9E0D',
    },
  },
  extend: {
    dropShadow: {
      mdAll: '0 0 8px rgba(0, 0, 0, 0.1)',
      smAll: '0 0 4px rgba(0, 0, 0, 0.06)',
    },
    gridTemplateRows: {
      layout: 'var(--header-height) 1fr',
    },
    width: { inherit: 'inherit' },
  },
  fontFamily: {
    body: ['Rubik'],
    display: ['Rubik'],
  },
  screens: {
    '2xl': '1536px',
    '3xl': '1920px',
    lg: '1024px',
    md: '768px',
    sm: '640px',
    xl: '1280px',
    xs: '480px',
  },
} as const;
