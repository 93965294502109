import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { UseQueryOptions } from 'shared/types';

import type { Model } from './types';

import { modelsKeys } from './queryKeys';

export type ShortListModels = {
  imageModels: Model[];
  textModels: Model[];
};

export const useShortListModelsQuery = (options: UseQueryOptions<ShortListModels> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<ShortListModels>(
        `${import.meta.env.VITE_CORE_API_ENDPOINT}/models/short-list`,
        {
          signal,
        },
      );

      return data;
    },
    queryKey: modelsKeys.shortList(),
    ...options,
  });
};
