import type { ChatClient } from 'nesa.js';

import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { ModelType } from 'features/ChatInput/ChatInput';
import type { Message } from 'shared/hooks/useDb';

import { queryClient } from 'app/App';
import { useUser } from 'app/stores/user';
import { ChatInput } from 'features/ChatInput';
import { CoachmarkModal } from 'features/CoachmarkModal';
import { chatKeys, messagesKeys } from 'shared/api/chat/queryKeys';
import { useChatByIdQuery } from 'shared/api/chat/useChatByIdQuery';
import { useChatMessagesQuery } from 'shared/api/chat/useChatMessagesQuery';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';
import { useShortListModelsQuery } from 'shared/api/models/useShortListModelsQuery';
import { usePreMessageMutation } from 'shared/api/user/usePreMessageMutation';
import { cleanObject } from 'shared/helpers/cleanObject';
import { useChatClient } from 'shared/hooks/useChatClient';
import { createMessage, saveUser } from 'shared/hooks/useDb';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { useStateX } from 'shared/hooks/useStateX';
import { useWallet } from 'shared/hooks/useWallet';
import { AnimatedRoute } from 'shared/ui/AnimatedRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';

import type { ChatStreamData, QueryParams, Stream } from './types';

import { defaultQueryParams } from './config';
import { getErrorMessage } from './helpers/getErrorMessage';
import { getModelParams } from './helpers/getModelParams';
import { ChatMessage } from './ui/ChatMessage';

export const ERROR_CODES = [311, 312, 313, 314, 315, 316, 317, 318, 319];

export const Chat = () => {
  const { id: chatId = '' } = useParams<{ id: string }>();
  const { state: locationState } = useLocation();
  const isMobile = useMaxWidthMediaQuery('md');

  const { localUser, setUser, user } = useUser();

  const { wallet: imageWallet } = useWallet('image');
  const { wallet: textWallet } = useWallet('text');

  const [state, setState] = useStateX<{
    ipfsLink?: string;
    isCoachmarkOpen: boolean;
    isRequestingImageSession?: boolean;
    isRequestingSession?: boolean;
    modelType: ModelType;
    readableStream?: Stream;
    selectedModel: null | string;
    status: 'idle' | 'progress' | 'timeout';
    transactionHash?: string;
    value: string;
  }>({
    isCoachmarkOpen: false,
    isRequestingImageSession: false,
    isRequestingSession: false,
    modelType: locationState?.modelType || 'text',
    selectedModel: locationState?.selectedModel || null,
    status: 'idle',
    value: '',
  });

  const { data: shortListModels, status: shortListModelsStatus } = useShortListModelsQuery();

  const modelsList = state.modelType === 'image' ? shortListModels?.imageModels : shortListModels?.textModels;
  const { data: model } = useGetModelByIdQuery(
    modelsList?.find((el) => el.name === state.selectedModel)?._id,
  );

  useEffect(() => {
    if (shortListModelsStatus === 'success') {
      setState((prev) => ({
        selectedModel: prev.selectedModel
          ? prev.selectedModel
          : (prev.modelType === 'image' ? shortListModels.imageModels : shortListModels.textModels).at(0)
              ?.name || null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortListModelsStatus, shortListModels]);

  const { mutateAsync: preMessageCheck } = usePreMessageMutation((user ? user._id : localUser?._id) || '');

  const client = useChatClient({
    mnemonic: textWallet?.secret?.data,
    modelName: model?.name || '',
    onClientChange: (params) => {
      state.readableStream?.destroy();

      if (!params.unmount && params.client) {
        requestSession(params.client, undefined, 'text');
      }
    },
  });

  const imageClient = useChatClient({
    mnemonic: imageWallet?.secret?.data,
    modelName: model?.name || '',
    onClientChange: (params) => {
      state.readableStream?.destroy();

      if (!params.unmount && params.client) {
        requestSession(params.client, undefined, 'image');
      }
    },
  });

  const { data: chat } = useChatByIdQuery(chatId);
  const { mutateAsync: addMessage } = useMutation({ mutationFn: createMessage });

  const { data: chatMessages, isLoading: isLoadingChatMessages } = useChatMessagesQuery(chatId);

  const handleLLmError = async (
    activeClient: ChatClient,
    qParams: Partial<QueryParams> = {},
    skipMsg?: boolean,
  ) => {
    state.readableStream?.destroy();

    activeClient?.requestCloseHeartbeat();

    await requestSession(activeClient, () => setTimeout(() => handleSend(qParams, { skipMsg }), 5000));
  };

  const requestSession = async (client: ChatClient, cb?: () => void, modelType?: ModelType) => {
    try {
      if (!client) {
        toast.error('No client defined');
        return;
      }

      setState({ isRequestingSession: true });

      const sessionStream = (await client.requestSession()) as Stream;

      sessionStream.on('data', (data: { code: number; message: string }) => {
        //  Processing transmission data
        const { code, message } = data;

        if (code === 200) {
          // Streaming data return for TransactionHash
          setState({ transactionHash: message || '' });

          if (cb && typeof cb === 'function') {
            cb();
          }

          if (locationState?.message && locationState?.modelType && locationState?.modelType === modelType) {
            setState({ modelType: locationState.modelType });

            setTimeout(() => {
              handleSend({ question: locationState.message.content }, { client, skipMsg: true });
            }, 100);
            // send a message to the model

            window.history.replaceState({}, '');
          }

          return;
        }
        if (code === 302) {
          setState({ isRequestingSession: false });
          return;
        }

        if (ERROR_CODES.includes(code)) {
          const { text } = getErrorMessage(message.toLowerCase());

          // if (type === 'insufficientBalance') {
          //   setErrorModalOpen({ insufficientBalance: true });
          // }

          toast.error(text);
          console.error('SESSION ERRROR', text, { data, message });
          setState({ isRequestingSession: false });
        }
      });
      sessionStream.on('end', () => {
        // End of transmission
        setState({ isRequestingSession: false });
      });
    } catch (e) {
      toast.error(getErrorMessage(`${e}`).text);

      setState({ isRequestingSession: false });
    }
  };

  const syncLatestMsg = async () => {
    const chatMessagesKey = messagesKeys.chatId(chatId);
    const msgs: Message[] = queryClient.getQueryData(chatMessagesKey) || [];

    const dbMessage = await addMessage(msgs[0]);

    queryClient.setQueryData(chatMessagesKey, [dbMessage, ...msgs.slice(1)]);
    // queryClient.setQueryData(['messages', chatId], [dbMessage, ...msgs.slice(1)]);

    setState({ status: 'idle' });
  };

  const handleSend = async (
    qParams: Partial<QueryParams> = {},
    { client: clientToUse, skipMsg }: { client?: ChatClient; skipMsg?: boolean } = {},
  ) => {
    if (!state.selectedModel) {
      toast.error('Model is not selected');
      return;
    }

    const content = qParams.question || state.value;

    setState({ status: 'progress', value: '' });
    const chatMessagesKey = messagesKeys.chatId(chatId);

    if (!skipMsg) {
      const message = await addMessage({ chatId, content, role: 'user' });

      const msgs = queryClient.getQueryData<Message[]>(chatMessagesKey) || [];

      queryClient.setQueryData(chatMessagesKey, [message, ...msgs]);
    }

    const userResponse = await preMessageCheck({
      modelName: state.selectedModel,
      modelType: state.modelType,
    });

    if (userResponse?.user) {
      if (user) {
        setUser(userResponse.user);
      } else {
        saveUser({
          _id: userResponse.user._id,
          energy: userResponse.user.energy,
          points: userResponse.user.points,
        });
        queryClient.setQueryData(['user'], userResponse.user);
      }
    }

    if (!model) {
      return;
    }

    setTimeout(() => {
      setState({ status: 'timeout' });
    }, 20 * 1000);

    const actualClient = clientToUse || (state.modelType === 'image' ? imageClient : client);

    const params: QueryParams = { ...defaultQueryParams, ...qParams, question: content };
    const modelParams = getModelParams(params, model);

    const startTime = DateTime.now();

    const chatRequest = {
      messages: [{ content, context: '', role: 'user' }],
      model: model?.name || '',
      model_params: modelParams,
      session_id: JSON.stringify(cleanObject({ session_id: chat?.sessionId || null })),
    };

    actualClient
      ?.requestChat(chatRequest)
      ?.then((readableStream1) => {
        const readableStream = readableStream1 as Stream;
        console.log('chat request', chatRequest);

        let execTime: number | undefined = undefined;

        setState({ readableStream });
        let isFirstResponseMsg = true;

        readableStream.on('data', (data: ChatStreamData) => {
          console.log('readable stream', data);

          // Processing transmission data
          if (data.code === 200) {
            if (!execTime) {
              execTime = Math.abs(startTime.diffNow('seconds').seconds);
            }

            try {
              const parsedSessionField = data.session_id && JSON.parse(data.session_id);

              console.log('parsed chat session id', parsedSessionField, chat?.sessionId);

              if (!chat?.sessionId) {
                queryClient.setQueryData(chatKeys.chatId(chatId), {
                  ...chat,
                  sessionId: parsedSessionField.session_id,
                });
              }

              const msgs: Message[] = queryClient.getQueryData(chatMessagesKey) || [];
              console.log('msgs', msgs);

              if (isFirstResponseMsg) {
                const msg: Message = {
                  chatId,
                  content: data.message,
                  executionTime: execTime,
                  id: 'generated' + Date.now(),
                  isIPFS: state.modelType === 'image',
                  role: 'ai',
                  timestamp: Date.now(),
                };

                queryClient.setQueryData(chatMessagesKey, [msg, ...msgs]);
              } else {
                queryClient.setQueryData(chatMessagesKey, [
                  { ...msgs[0], content: msgs[0].content + data.message },
                  ...msgs.slice(1),
                ]);
              }

              isFirstResponseMsg = false;
            } catch (e) {
              console.error('json parse error');
            }
          }

          if (data.code === 203) {
            syncLatestMsg();
          }

          if (data.code === 205) {
            const parsed = data.message ? JSON.parse(data.message) : {};

            if (parsed.msg === 'session status mismatch') {
              setTimeout(() => {
                handleLLmError(actualClient, params, true);
              }, 0);

              return;
            }

            const isLLMError = ['LLM Network error', 'LLM Backend error']
              .map((s) => s.toLowerCase())
              .includes((parsed.msg as string).toLowerCase());
            const isAbnormal = (parsed.msg as string).toLowerCase() === 'LLM Abnormal'.toLowerCase();
            const text = isLLMError
              ? 'Network experiencing extreme load, try again later.'
              : isAbnormal
                ? 'LLM Network error'
                : parsed.msg;

            if (parsed.msg.includes('balance insufficient')) {
              // setErrorModalOpen({ insufficientBalance: true });
              // setState({ isStartConvOpen: true, transactionHash: undefined });
            } else {
              text && toast.error(text);
            }
          }
        });
        readableStream.on('end', async () => {
          readableStream.destroy();
          setState({ readableStream: undefined });
        });
      })
      .catch((error) => {
        console.log('ERRROR', error);
      });
  };

  const onRegenerateMessage = async (messageId: string) => {
    const messageIndex = (chatMessages || []).findIndex((el) => el.id === messageId);
    const message = chatMessages?.at(messageIndex);
    const prevMessage = chatMessages?.find((el, i) => el.role === 'user' && i > messageIndex);

    if (messageIndex === -1 || !prevMessage || !message) return;

    handleSend({ question: prevMessage.content }, { skipMsg: true });
    // await deleteMessage({ messageId: message.id });
  };

  return (
    <AnimatedRoute className={twMerge('flex max-h-dvh flex-col overflow-hidden pt-20', isMobile && 'h-dvh')}>
      <h2 className="mb-6 flex items-center justify-center text-center text-lg">
        {chat ? chat?.chatName || `Chat` : <Spinner />}
      </h2>

      {isLoadingChatMessages ? (
        <div className="flex h-full items-center justify-center font-light text-corduroy-500">
          <Spinner className="size-5" />
        </div>
      ) : (chatMessages || [])?.length > 0 ? (
        <div className="mb-4 mt-auto flex flex-col-reverse gap-2 overflow-y-scroll">
          {chatMessages?.map((message, i) => {
            return (
              <ChatMessage
                disabled={Boolean(state.status === 'progress' || state.isRequestingSession)}
                key={message.id}
                message={message}
                onRegenerateMessage={onRegenerateMessage}
                prevMessage={chatMessages[i + 1]}
              />
            );
          })}
        </div>
      ) : (
        <div className="flex h-full items-center justify-center font-light text-corduroy-500">
          No messages yet
        </div>
      )}

      <div className="relative w-full">
        {state.isRequestingSession && (
          <div className="absolute bottom-6 right-4 z-10 flex max-w-48 items-center justify-center gap-2 text-xs font-light leading-none text-corduroy-600 sm:max-w-max">
            <Spinner className="size-3 min-w-3" /> Initializing your private tunnel, just a few seconds more
            for your first AI request
          </div>
        )}

        <ChatInput
          actionSlot={
            <div className="relative z-10">
              <Button
                className="size-9 max-h-9 px-2"
                isLoading={state.status === 'progress' || state.isRequestingSession}
                onClick={() => handleSend()}
              >
                <Icon className="size-full" name="arrowUpLong" />
              </Button>
            </div>
          }
          disabled={state.status === 'progress' || state.isRequestingSession}
          isLoading={state.isRequestingSession}
          onChange={(e) => setState({ value: e.target.value })}
          onCoachmarkClick={() => setState({ isCoachmarkOpen: true })}
          onModelTypeChange={(type) => {
            setState({
              modelType: type,
              selectedModel:
                (type === 'image' ? shortListModels?.imageModels : shortListModels?.textModels)?.at(0)
                  ?.name || null,
            });
          }}
          onSelectedModelChange={(selectedModel) => setState((prev) => ({ ...prev, selectedModel }))}
          onSend={() => handleSend()}
          selectedModel={state.selectedModel}
          type={state.modelType}
          value={state.value}
        />
      </div>

      <CoachmarkModal
        isOpen={state.isCoachmarkOpen}
        modelType={state.modelType}
        onOpenChange={(val) => setState({ isCoachmarkOpen: val })}
        onSelect={(text) => {
          setState({ isCoachmarkOpen: false, value: text });
        }}
      />
    </AnimatedRoute>
  );
};
