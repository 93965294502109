import { useCustomCountUp } from 'shared/hooks/useCustomCountUp';

type Props = {
  points: number;
};

export const Points = ({ points }: Props) => {
  const [counterRef] = useCustomCountUp<HTMLDivElement>(points, true, {
    decimals: 0,
    duration: 1,
    separator: ',',
  });
  return <div ref={counterRef}></div>;
};
