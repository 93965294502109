import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  headers: {
    'Access-Control-Allow-Origin': `${import.meta.env.VITE_API_ENDPOINT}`,
    'Content-Type': 'application/json;charset=utf-8',
  },
  timeout: 15000,
  withCredentials: true,
});

export const axiosTGInstance = axios.create({
  baseURL: import.meta.env.VITE_SHH_API_ENDPOINT,
  headers: {
    'Access-Control-Allow-Origin': `${import.meta.env.VITE_SHH_API_ENDPOINT}`,
    Authorization: localStorage.getItem('user') ? `Bearer ${localStorage.getItem('user')}` : undefined,
    'Content-Type': 'application/json;charset=utf-8',
  },
  timeout: 15000,
  withCredentials: true,
});

export const setupInterceptors = (accessToken?: string) => {
  localStorage.setItem('user', accessToken || '');

  axiosTGInstance.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );
};
