import type { ComponentProps, ReactElement } from 'react';
import { createContext, forwardRef, useContext, useId, useState } from 'react';

import * as RTabs from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const LayoutContext = createContext<null | string>(null);
const ActiveTabContext = createContext<null | string>(null);

const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (context == null) throw new Error('Use context without provider');

  return context;
};

const useActiveTab = () => {
  const context = useContext(ActiveTabContext);

  return context;
};

const List = forwardRef<HTMLDivElement, ComponentProps<typeof RTabs.List>>(
  ({ children, className, ...props }, forwardRef) => {
    const layoutId = useId();

    return (
      <LayoutContext.Provider value={layoutId}>
        <RTabs.List
          {...props}
          className={twMerge('relative flex border-b border-corduroy-200', className)}
          ref={forwardRef}
        >
          {children}
        </RTabs.List>
      </LayoutContext.Provider>
    );
  },
);

const Trigger = forwardRef<HTMLButtonElement, ComponentProps<typeof RTabs.Trigger>>(
  ({ children, className, value, ...props }, forwardRef) => {
    const layoutId = useLayoutContext();
    const activeTab = useActiveTab();

    return (
      <RTabs.Trigger
        {...props}
        className={twMerge(
          'relative flex items-center gap-2 px-4 pb-2.5 pt-1.5 text-sm font-medium text-corduroy-900',
          'disabled:cursor-not-allowed disabled:text-corduroy-500',
          className,
        )}
        ref={forwardRef}
        value={value}
      >
        {children}
        {activeTab === value && (
          <motion.div
            className="absolute inset-x-0 -bottom-px h-0.5 bg-primary-800"
            layoutId={layoutId}
            style={{ originY: '0px' }}
          />
        )}
      </RTabs.Trigger>
    );
  },
);

const Content = RTabs.Content;

type RootProps<Tab extends string> = {
  classNameContent?: string;
  defaultValue?: Tab;
  onValueChange?: (tab: Tab) => void;
  tabs: ReactElement;
  value?: Tab;
};

const Root = <Tab extends string>({
  children,
  classNameContent,
  tabs,
  ...props
}: Omit<ComponentProps<typeof RTabs.Root>, keyof RootProps<Tab>> & RootProps<Tab>) => {
  const [activeTab, setActiveTab] = useState(props.defaultValue);

  const isControlled = typeof props.onValueChange === 'function' && typeof props.value === 'string';

  const value = isControlled ? props.value : activeTab;
  const onTabChange = isControlled ? props.onValueChange : setActiveTab;

  return (
    <ActiveTabContext.Provider value={value || null}>
      <AnimatePresence>
        <RTabs.Root {...props} onValueChange={(tab) => onTabChange?.(tab as Tab)} value={value}>
          {tabs}
          <div className={classNameContent}>{children}</div>
        </RTabs.Root>
      </AnimatePresence>
    </ActiveTabContext.Provider>
  );
};

export const Tabs = {
  Content,
  List,
  Root,
  Trigger,
};
