import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { axiosTGInstance } from 'shared/config/axiosInstance';

import type { SignUpResponse } from './types';

export const useUserSignUpMutation = (options: UseMutationOptions<SignUpResponse, Error> = {}) => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await axiosTGInstance.post<SignUpResponse>(`/user/signup/web`);

      return data;
    },
    ...options,
  });
};
